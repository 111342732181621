import React, { useReducer, useState } from "react"
import Img from "gatsby-image"
import sanitizeHtml from "sanitize-html"

import { Formik, Form } from "formik"
import classNames from "classnames"
import MedicineInfo from "./MedicineInfo"
import MedicineQuantityInput from "./MedicineQuantityInput"
import AssistanceCalculator from "./Order/components/AssistanceCalculator"
import {
  AssistanceCalculatorReducer,
  initialState,
} from "./Order/utils/AssistanceCalculatorReducer"

import { changeQuantity } from "./services/cart"
import styles from "../Elements/utils/elements.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { navigate } from "gatsby"

const optionForSup = {
  allowedTags: ["sup"],
  allowedAttributes: {
    br: ["class"],
  },
}
const AddMedicine = ({
  addToCart,
  medicine,
  dispatch,
  moduleName,
  coupons,
  image,
  pageContext,
  mobile = false,
  isUpdate = false,
}) => {
  const coupon = coupons.find(
    (coupon) => coupon.includedProducts === medicine.productTitle?.[0]
  )
  const [
    calculatorState,
    calculatorDispatch,
  ] = useReducer(AssistanceCalculatorReducer, { ...initialState() })
  const [orderDetails, setOrderDetails] = useState({})
  const { medicineQuantity } = calculatorState

  return (
    <Formik
      initialValues={{
        qty: medicineQuantity,
      }}
      onSubmit={(values) => {
        dispatch({ type: "HIDE_SLIDING_MODAL" })
        dispatch({ type: "HIDE_MODAL" })

        if (addToCart) addToCart(medicine, values.qty, orderDetails)
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div className="mx-2 mx-1-mobile">
            <MedicineInfo
              medicine={medicine}
              module={moduleName}
              mobile={mobile}
              disableRxLogo={true}
            />
            <div className="is-flex has-text-weight-bold mb-1">
              <p>Php {orderDetails?.orderTotal}</p>
            </div>
            <div className="mb-2 is-hidden-mobile">
              <p>
                Consult with your healthcare provider to see if Saxenda is the
                right weight loss treatment for you.
              </p>
            </div>

            <div className="is-hidden">
              <AssistanceCalculator
                medicine={medicine}
                quantity={values.qty}
                coupon={coupon}
                image={image}
                setOrderDetails={setOrderDetails}
              />
            </div>

            <div className="is-hidden-desktop">
              <p className="has-text-weight-bold mb-1 mb-0-mobile">Quantity:</p>
              <MedicineQuantityInput
                changeQuantity={changeQuantity}
                setFieldValue={setFieldValue}
                qty={values.qty}
                value={values.qty}
                min={1}
                max={10}
                calculatorDispatch={calculatorDispatch}
              />
            </div>

            <div className="notification is-light has-text-left mb-1 px-1 is-flex">
              {/* assistance calculator header */}
              <div>
                <Img fixed={image} />
              </div>

              <h5 className=" ml-1" style={{ margin: "auto" }}>
                <span className="has-text-weight-bold">
                  {coupon?.notes.split(" ").slice(0, 5).join(" ")}
                </span>{" "}
                <span
                  className="has-text-weight-medium"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(
                      coupon?.notes
                        .split(" ")
                        .slice(5)
                        .join(" ")
                        .replace("Saxenda", "Saxenda<sup>®</sup>"),
                      optionForSup
                    ),
                  }}
                />
              </h5>
            </div>
            <div className="is-hidden-touch">
              <p className="has-text-weight-bold mb-1">Quantity:</p>
              <MedicineQuantityInput
                changeQuantity={changeQuantity}
                setFieldValue={setFieldValue}
                qty={values.qty}
                value={values.qty}
                min={1}
                max={10}
                calculatorDispatch={calculatorDispatch}
              />
            </div>

            <div className="columns mx-1 mx-0-mobile is-mobile">
              <div className="column one-half">
                <button
                  type="button"
                  onClick={() => {
                    navigate(pageContext?.backPath)
                  }}
                  className={classNames(
                    styles["new_buttonOutline"],
                    " px-2 mt-1 mb-2",
                    mobile
                      ? "has-text-primary has-border-primary"
                      : "has-text-secondary"
                  )}
                >
                  {<FontAwesomeIcon icon={faChevronLeft} />}
                </button>
              </div>

              <div className="column one-half">
                <button
                  type="submit"
                  className={classNames(
                    " px-3 mt-1 mb-2",
                    styles["new_button"],
                    mobile ? "is-primary" : "is-secondary"
                  )}
                  disabled={values.qty > 10 || values.qty < 1}
                >
                  Next: Checkout
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddMedicine
