import React, { useContext, useEffect, useState } from "react"
import ImageGallery from "react-image-gallery"

import { navigate } from "gatsby"
import Layout from "../../Layout/index"
import { EpharmacyContext } from "../EpharmacyContext/EpharmacyContext"
import Container from "../../Layout/Container"
import AddMedicineModal from "../AddMedicineModal"
import { AppContext } from "../../../context/AppContext"
import useSaxendaCoupons from "./hooks/useSaxendaCoupons"
import useAssistanceCalculatorImages from "./hooks/useAssistanceCalculatorImages"
import { medicine } from "../../Upload/utils/staticItems"
import AddMedicine from "../AddMedicine"
import useOrderImages from "./hooks/useOrderImages"

const EpharmacyOrder = ({ pageContext }) => {
  const { module } = pageContext
  const { state, dispatch } = useContext(AppContext)
  const { epharmacyState, epharmacyDispatch } = useContext(EpharmacyContext)
  const orders = useOrderImages()
  const saxendaCoupons = useSaxendaCoupons()

  const imageData = useAssistanceCalculatorImages()
  const assistanceCalculatorImage =
    imageData?.calculatorIcon?.childImageSharp?.fixed

  const images = [
    {
      original: orders.saxendaThumbnail01.childImageSharp.fluid.src,
      thumbnail: orders.saxendaThumbnail01.childImageSharp.fluid.src,
    },
    {
      original: orders.saxendaThumbnail02.childImageSharp.fluid.src,
      thumbnail: orders.saxendaThumbnail02.childImageSharp.fluid.src,
    },
    {
      original: orders.saxendaThumbnail03.childImageSharp.fluid.src,
      thumbnail: orders.saxendaThumbnail03.childImageSharp.fluid.src,
    },
    {
      original: orders.saxendaThumbnail04.childImageSharp.fluid.src,
      thumbnail: orders.saxendaThumbnail04.childImageSharp.fluid.src,
    },
  ]

  const addMedicineToCart = async (item, qty, orderDetails) => {
    const medicineList = [...state.medicines]
    const { totalQuantity, assistanceQuantity, orderTotal } = orderDetails
    medicineList.push({
      ...item,
      qty: qty,
      totalQuantity: totalQuantity,
      assistanceQuantity: assistanceQuantity,
      orderTotal: orderTotal,
    })
    await epharmacyDispatch({
      type: "SAVE_MEDICINES",
      payload: medicineList,
    })

    epharmacyDispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        medicines: medicineList,
      },
    })

    dispatch({
      type: "SHOW_TOAST",
      payload: {
        message: `Added ${item.DrugName} to cart.`,
        color: "success",
      },
    })
    epharmacyDispatch({
      type: "SAVE_SELECTED_MEDICINE",
      payload: null,
    })

    navigate(pageContext?.nextPath)
  }

  useEffect(() => {
    epharmacyDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [epharmacyDispatch])

  return (
    <Layout
      {...module}
      pageContext={pageContext}
      currentStep={2}
      totalSteps={3}
    >
      <Container isCentered fullhd={8} desktop={10}>
        <div className="columns is-full is-multiline">
          <div className="column is-6-fullhd is-6-desktop is-12-tablet">
            <div className="is-hidden-touch">
              <ImageGallery
                items={images}
                thumbnailPosition={"left"}
                showPlayButton={false}
                showBullets={false}
                showNav={false}
                showFullscreenButton={false}
              />
            </div>
            <div className="is-hidden-desktop">
              <ImageGallery
                items={images}
                showThumbnails={false}
                showPlayButton={false}
                showBullets={false}
                showNav={false}
                showFullscreenButton={false}
              />
            </div>
          </div>
          <div className="column">
            <AddMedicine
              addToCart={addMedicineToCart}
              medicine={medicine}
              dispatch={dispatch}
              moduleName={""}
              coupons={saxendaCoupons}
              image={assistanceCalculatorImage}
              pageContext={pageContext}
            />
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default EpharmacyOrder
