import { useStaticQuery, graphql } from "gatsby"

const useOrderImages = () => {
  const data = useStaticQuery(graphql`
    {
      saxendaThumbnail01: file(
        relativePath: { eq: "saxenda__thumbnail01.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      saxendaThumbnail02: file(
        relativePath: { eq: "saxenda__thumbnail02.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      saxendaThumbnail03: file(
        relativePath: { eq: "saxenda__thumbnail03.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      saxendaThumbnail04: file(
        relativePath: { eq: "saxenda__thumbnail04.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return data
}

export default useOrderImages
